import React from 'react'

import { WebVector, MobileVectorAlt, GraphicsVector } from '../Icons'

export default function Services() {
  return (
    <div>
      <section id="wrapper">
        <section id="one" className="wrapper spotlight style1">
          <div className="inner">
            <WebVector width={400} height={400} />
            <div className="content" id="web">
              <h2 className="major">Web & Applications Development</h2>
              <p>
                From web design to applications powered by web-based technologies. 
                A whole range of solutions are made possible, from e-commerce, 
                headless API-based applications, small-scale database management, 
                multiplatform implementations, and even Single Page Applications.
              </p>
            </div>
          </div>
        </section>

        <section id="two" className="wrapper alt spotlight style2">
          <div className="inner">
            <MobileVectorAlt width={400} height={400} />
            <div className="content" id="mobile">
              <h2 className="major">Mobile Apps Development</h2>
              <p>
                In the increasingly growing trend of Bring Your Own Device (BYOD), 
                a mobile solution is essential for any business looking to 
                set foot on the tech world. From marketing system apps, 
                tracking systems, and even games! 
              </p>
            </div>
          </div>
        </section>

        <section id="three" className="wrapper spotlight style3">
          <div className="inner">
            <GraphicsVector width={400} height={400} />
            <div className="content" id="graphics">
              <h2 className="major">Graphic Design</h2>
              <p>
                Looking to create a visual identity for your brand? Or 
                do you want a more visual presence for your business? 
                We offer graphic design services ranging from stock graphic 
                creation, logo design, and brand design consultation 
                services. Got a Smartwatch? We can make watchfaces for 
                any Samsung watch or any device that supports Facer! 
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}