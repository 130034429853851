import React from 'react'
import { Link } from 'gatsby'

import spectrum from '../../assets/img/sd_feature.png'
import everguard from '../../assets/img/eg-featured.png'
import luckyfinders from '../../assets/img/luckyfinders.png'
import malabrigo from '../../assets/img/malabrigo.png'

export default function Projects () {
    return (
      <section id="four" className="wrapper alt style1">
        <div className="inner">
          <h2 className="major">Projects</h2>
          <p>
            Feel free to take a look around and sample some of our works. If you
            feel like you want your idea to come to life, don't hesitate to{' '}
            <a href="#footer" className="contact-link">
              send us an email.{' '}
            </a>
            Don't worry, we don't use robots to reply here.
          </p>
          <section className="features">
            <article>
              <Link to="/spectrum-drop" className="image">
                <img src={spectrum} alt="" />
              </Link>
              <h3 className="major">Spectrum Drop</h3>
              <p>
                A color-tile matching game released for Android mobile devices.
              </p>
              <Link to="/spectrum-drop" className="special">
                Learn more
              </Link>
            </article>
            <article>
              <Link to="/everguard" className="image">
                <img src={everguard} alt="" />
              </Link>
              <h3 className="major">Everguard</h3>
              <p>
                GPS tracking to SMS app released for Android mobile devices.
              </p>
              <Link to="/everguard" className="special">
                Learn more
              </Link>
            </article>
            <article>
              <Link to="/lucky-finders" className="image">
                <img src={luckyfinders} alt="" />
              </Link>
              <h3 className="major">Lucky Finders</h3>
              <p>An e-commerce platform for mostly niche and rare items.</p>
              <Link to="/lucky-finders" className="special">
                Learn more
              </Link>
            </article>
            <article>
              <Link to="/punta-malabrigo" className="image">
                <img src={malabrigo} alt="" />
              </Link>
              <h3 className="major">Punta Malabrigo</h3>
              <p>
                Website and showcase platform for the beach resort in Lobo,
                Batangas.
              </p>
              <Link to="/punta-malabrigo" className="special">
                Learn more
              </Link>
            </article>
          </section>
        </div>
      </section>
    );
}