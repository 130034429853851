import React from 'react';
import Typed from 'react-typed'

import Layout from '../components/Layout'

import Services from '../components/page-parts/services'
import Projects from '../components/page-parts/projects'

import xm from '../assets/img/xm.png'

import config from '../../config';

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <div className="logo">
          <img src={xm} alt="" />
        </div>
        <h2>{config.heading}</h2>
        <p className="typing">
          WE CREATE <Typed
                      strings={['WEBSITES', 'MOBILE APPS', 'OPPORTUNITIES']}
                      typeSpeed={80}
                      backSpeed={70}
                      backDelay={3000}
                      loop
                    />
        </p>
      </div>
    </section>
    <Services />
    <Projects />
  </Layout>
);

export default IndexPage;
